<template>
  <!-- 医院端 订单管理 -- 我购买的课程  /web/coursesIbought -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">我的课程</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="courseName"
                type="text"
                size="small"
                class="unitNamewidth"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
            <div title="订单日期" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 6rem">订单日期:</span>
              <el-date-picker
                  clearable
                  style="width: 260px"
                  size="small"
                  format="yyyy-MM-dd"
                  v-model="orderTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="课程名称"
                align="left"
                prop="compName"
                show-overflow-tooltip
                min-width="400"
              >
                <template slot-scope="scope">
                  <div style="display: flex">
                    <el-image
                      style=" height: 128px; border-radius: 10px;max-width:220px;flex:1"
                      :src="scope.row.logo || require('@/assets/develop.png')"
                    ></el-image>
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        margin-left: 15px;
                      "
                    >
                      <div>
                        {{ scope.row.courseName }}
                      </div>
                      <div>共 {{ scope.row.lessonNum }} 课时</div>
                      <div>适用区域：{{ scope.row.areaNamePath }}</div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="培训类型"
                align="left"
                prop="trainTypeName"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.trainTypeName || "--" }}
                </template>
              </el-table-column>

              <el-table-column
                label="购买数量"
                align="left"
                prop="totalCount"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.totalCount || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="已使用数量"
                align="left"
                prop="usedCount"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.usedCount || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="剩余数量"
                align="left"
                prop="remainCount"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.remainCount || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="订单日期"
                align="left"
                prop="orderTime"
                show-overflow-tooltip
                min-width="120"
              />
              <el-table-column label="操作" align="center" width="350px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleEdit(scope.row)"
                    >查看课程</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleAdd(scope.row)"
                    >学员列表</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    :disabled="scope.row.isFinish"
                    @click="GenerateQRcode(scope.row)"
                    >继续购买</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="onUploadMaterials(scope.row)"
                    >上传材料</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="showDocsDialog(scope.row)"
                    >下载档案</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="培训类型"
      :visible="generateQRcodeImgLoading"
      :before-close="doClose"
      width="500px"
    >
      <div ref="pic_content">
        <img style="width: 100%" :src="generateQRcodeImg" alt="" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="generateQRcodeImgLoading = false" size="small"
          >关 闭</el-button
        >
        <!-- <el-button
          class="bgc-bv"
          @click="downloadGenerateQRcode(generateQRcodeImg,'6666')"
          size="small"
          >下 载</el-button
        > -->
      </span>
    </el-dialog>

    <!-- 上传材料 -->
    <el-dialog
      title="上传材料"
      :visible="showUploadMaterials"
      :before-close="closeUploadMaterials"
      width="50%"
    >
      <el-row>
        <el-col :span="12">
          <span style="width: 90px; text-align: right">机构名称：</span>
          <span>{{ compData.compName }}</span>
        </el-col>
        <el-col :span="12">
          <span style="width: 100px">培训有效期：</span>
          <span style="width: 250px">
            {{ compData.startDate || "--" }} 至 {{ compData.endDate || "--" }}
          </span>
        </el-col>
      </el-row>

      <el-row style="margin-top: 20px">
        <el-col :span="12">
          <span style="width: 90px; text-align: right">联系人：</span>
          <span>{{ compData.compUser || "--" }}</span>
        </el-col>
        <el-col :span="12">
          <span style="width: 100px">联系方式：</span>
          <span style="width: 250px">{{ compData.compPhone || "--" }}</span>
        </el-col>
      </el-row>

      <el-form
        ref="form"
        :model="formUploadMaterials"
        label-width="100px"
        style="margin-top: 20px"
      >
        <el-form-item label="单位介绍信：">
          <el-upload
            class="upload-demo"
            action
            drag
            multiple
            show-file-list
            :file-list="formUploadMaterials.unitIntroductionLetter"
            accept=".png,.jpg,.jpeg,.pdf"
            :on-remove="(el, list) => removeFile(el, list, 1)"
            :on-preview="previewFile"
            :http-request="(el) => uploadFile(el, 1)"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传图片或PDF文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>

        <el-form-item label="申请表：">
          <el-upload
            class="upload-demo"
            action
            drag
            multiple
            show-file-list
            :file-list="formUploadMaterials.applicationForm"
            accept=".png,.jpg,.jpeg,.pdf"
            :on-remove="(el, list) => removeFile(el, list, 2)"
            :on-preview="previewFile"
            :http-request="(el) => uploadFile(el, 2)"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传图片或PDF文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="其他材料：">
          <el-upload
            class="upload-demo"
            action
            drag
            multiple
            show-file-list
            :file-list="formUploadMaterials.otherMaterials"
            accept=".png,.jpg,.jpeg,.pdf"
            :on-remove="(el, list) => removeFile(el, list, 3)"
            :on-preview="previewFile"
            :http-request="(el) => uploadFile(el, 3)"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传图片或PDF文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div class="btn-box flexcc">
        <el-button @click="closeUploadMaterials" class="bgc-bv"
          >取 消</el-button
        >
        <el-button class="bgc-bv" @click="doAddSave">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 预览 -->
    <el-dialog
      title="预览"
      :visible.sync="previewLoding1"
      @close="uploadLodClose"
      :modal="false"
      width="50%"
      top="2%"
      center
      v-dialogDrag
    >
      <div class="ovy-a" style="height: 600px">
        <div
          id="pdf-cert1"
          style="height: 600px"
          v-if="fileType == 'pdf' || fileType == 'PDF'"
        ></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="培训档案选择"
      :visible.sync="docsDialogVisible"
      width="50%"
      top="5%"
      :center="true"
      :before-close="closeDocsDialog"
    >
      <div>
        <div class="ovy-a">
          <div class="downloadStyle">
            <el-form
              ref="docsFormnew"
              :model="docsFormnew"
              class="docsDialog"
              v-show="danganList.length"
            >
              <el-form-item v-for="(val, key) in danganList" :key="key">
                <el-checkbox v-model="docsFormnew[val.dictCode]">{{
                  val.dictValue
                }}</el-checkbox>
              </el-form-item>
            </el-form>
          </div>
          <el-checkbox v-model="agreeState" style="margin: 0 3%">
            <span style="color: red !important">
              根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，
              不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。我已阅读并同意上述内容。
            </span>
          </el-checkbox>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <div>
          <el-button class="bgc-bv" @click="confirmDocsDialog">确定</el-button>
          <el-button @click="closeDocsDialog">取消</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pdf from "pdfobject";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive, acceptTypeImg } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "UnitList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    let currentDate = new Date();
    let startDate = currentDate.getFullYear() + '-01-01';
    let endMonth = currentDate.getMonth() + 1;
    let endDay = currentDate.getDate();
    if (endMonth < 10) {
        endMonth = "0" + endMonth;
    }
    if (endDay < 10) {
       endDay = "0" + endDay;
    }
    let endDate = currentDate.getFullYear() + "-" + endMonth + "-" + endDay;

    return {
      courseName: "", // 课程名称
      orderTime: [startDate, endDate],   //订单时间
      projectData: "", // 班级数据
      showUploadMaterials: false, // 上传材料弹窗显示状态
      // 上传材料表单数据
      formUploadMaterials: {
        unitIntroductionLetter: [], // 单位介绍信列表
        applicationForm: [], // 申请表
        otherMaterials: [], // 其他材料
      },
      compData: "", // 机构数据
      previewLoding1: false, // 预览状态
      ImgSrc: "", // 预览图片的地址
      fileType: "",
      //档案下载
      danganList: [],
      docsFormnew: {},
      agreeState: false, //弹框是否同意
      selectedItem: {},
      docsDialogVisible: false,
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 编辑课程材料
    doAddSave() {
      let data = {
        projectId: this.projectData.projectId,
        projectCourseId: this.projectData.projectCourseId,
        otherMaterials: this.formUploadMaterials.otherMaterials,
        applicationForm: this.formUploadMaterials.applicationForm,
        unitIntroductionLetter: this.formUploadMaterials.unitIntroductionLetter,
      };
      this.$post("/biz/projectCourse/material/update", data)
        .then((res) => {
          ;
          if (res.status == 0) {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.showUploadMaterials = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 删除文件
    removeFile(file, fileList, type) {
      if (type == 1) {
        this.formUploadMaterials.unitIntroductionLetter = fileList;
      }
      if (type == 2) {
        this.formUploadMaterials.applicationForm = fileList;
      }
      if (type == 3) {
        this.formUploadMaterials.otherMaterials = fileList;
      }
    },
    // 上传单位介绍信
    uploadFile(res, type) {
      if (acceptTypeImg(res)) {
        this.$message.error("上传文件的大小不能超过 10MB!");
        return false;
      } else {
        let formData = new FormData();
        let fileNmae = res.file.name; // 文件名
        let extension = fileNmae.replace(/.+\./, ""); // 文件类型
        if (
          !".png,.jpg,.jpeg,.bmp,.gif,.pdf,.PDF".includes(
            extension[extension.length - 1]
          )
        ) {
          this.$message.warning({
            message: "只能上传图片与pdf格式的文件！",
            duration: 1000,
          });
          return false;
        }

        formData.append("folder ", "QUESTION");
        formData.append("file ", res.file);
        formData.append("fileType ", extension);

        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            console.log(result);
            console.log(this.formUploadMaterials);
            if (type == 1) {
              this.formUploadMaterials.unitIntroductionLetter.push({
                pictureKey: result.data.fileKey,
                pictureType: "20",
                pictureUrl: result.data.fileURL,
                pictureName: res.file.name,
                name: res.file.name,
                url: result.data.fileURL,
              });
            }

            if (type == 2) {
              this.formUploadMaterials.applicationForm.push({
                pictureKey: result.data.fileKey,
                pictureType: "10",
                pictureUrl: result.data.fileURL,
                pictureName: res.file.name,
                name: res.file.name,
                url: result.data.fileURL,
              });
            }

            if (type == 3) {
              this.formUploadMaterials.otherMaterials.push({
                pictureKey: result.data.fileKey,
                pictureType: "30",
                pictureUrl: result.data.fileURL,
                pictureName: res.file.name,
                name: res.file.name,
                url: result.data.fileURL,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    // 预览文件
    previewFile(res) {
      this.previewLoding1 = true;
      this.ImgSrc = "";
      let extension = res.pictureKey.replace(/.+\./, "");
      this.fileType = extension;
      console.log(extension);
      if (extension == "pdf" || extension == "PDF") {
        console.log(res.pictureUrl);

        this.$nextTick(() => {
          pdf.embed(res.pictureUrl, "#pdf-cert1");
        });
      } else {
        this.ImgSrc = res.pictureUrl;
      }
    },
    // 关闭上传材料弹窗
    closeUploadMaterials() {
      this.showUploadMaterials = false;
      // 初始化上传材料表单数据
      this.formUploadMaterials = {
        unitIntroductionLetter: [], // 单位介绍信列表
        applicationForm: [], // 申请表
        otherMaterials: [], // 其他材料
      };
    },
    // 点击上传材料
    onUploadMaterials(e) {
      this.projectData = e;
      this.showUploadMaterials = true;
      let data = {
        projectCourseId: e.projectCourseId, // 班级课程id
        projectId: e.projectId, // 班级id
      };
      this.$post("/biz/projectCourse/material/companyInfo", data)
        .then((res) => {
          ;
          if (res.status == 0) {
            res.data.startDate = new Date(res.data.startDate).Format(
              "yy年MM月dd日"
            );
            res.data.endDate = new Date(res.data.endDate).Format(
              "yy年MM月dd日"
            );

            if (res.data.unitIntroductionLetter) {
              res.data.unitIntroductionLetter.forEach((element) => {
                element.name = element.pictureName;
              });
            }

            if (res.data.otherMaterials) {
              res.data.otherMaterials.forEach((element) => {
                element.name = element.pictureName;
              });
            }
            if (res.data.applicationForm) {
              res.data.applicationForm.forEach((element) => {
                element.name = element.pictureName;
              });
            }

            this.compData = res.data;
            this.formUploadMaterials.unitIntroductionLetter =
              res.data.unitIntroductionLetter || []; // 单位介绍信列表
            this.formUploadMaterials.otherMaterials =
              res.data.otherMaterials || []; // 其他材料
            this.formUploadMaterials.applicationForm =
              res.data.applicationForm || []; // 申请表
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    DownloadFile() {
      this.$post("/sys/download/hospital/asyncDownloadFile", {
        projectId: e.projectId,
      }).then((re) => {
        if (re.status == "0") {
          if (!this.downloadItems.includes(re.data.taskId)) {
            this.$store.dispatch("pushDownloadItems", re.data.taskId);
          } else {
            this.$message.warning(
              "[" + re.data.fileName + "]已经申请下载,请耐心等待"
            );
          }
        } else {
          this.$message.error(re.message);
        }
      });
    },
    //  取消下载
    closeDocsDialog() {
      this.docsDialogVisible = false;
      this.agreeState = false;
      this.$refs["docsFormnew"].resetFields();
      for (let key in this.docsFormnew) {
        this.docsFormnew[key] = false;
      }
    },
    // 下载培训档案
    showDocsDialog(raw) {
      this.docsDialogVisible = true;
      this.selectedItem.id = raw.projectId;
      this.getList(raw.projectId);
      this.selectedItem.name = raw.courseName;
      this.trainTypeId = raw.trainTypeId;
      this.projectSource = raw.projectSource;
      this.selectedItem.code = raw.projectCode;
    },
    getList(id) {
      this.$post("/run/project/archives", { projectId: id }).then((res) => {
        this.danganList = res.data;
      });
    },
    // 下载档案
    confirmDocsDialog() {
      if (!this.agreeState) {
        this.$message({
          type: "warning",
          message: "请先阅读提示内容并同意",
        });
        return;
      }
      let params = {
        fileDTOS: [],
      };
      for (let i in this.docsFormnew) {
        if (this.docsFormnew[i]) {
          params.fileDTOS.push({
            fileType: i,
            fileName:
              this.selectedItem.name +
              "-" +
              this.danganList.find((el) => el.dictCode == i).dictValue,
            param: {
              projectId: this.selectedItem.id,
            },
          });
        }
      }
      if (!params.fileDTOS.length) {
        this.$message.error("请至少选择一项进行打印");
        return;
      }
      this.$post("/biz/projectUser/checkCertificate", {
        projectId: this.selectedItem.id,
      }).then((ret) => {
        if (ret.status == "0") {
          if (!ret.data) {
            this.$confirm(
              "存在未上传证件照片的学员，合格证上将取用认证时的照片",
              "提示",
              {
                confirmButtonText: "继续下载",
                showCancelButton: false,
              }
            )
              .then(() => {
                this.doHospitalFile(params);
              })
              .catch(() => {});
          } else {
            this.doHospitalFile(params);
          }
        }
      });
    },
    doHospitalFile(params) {
      this.$post("/sys/download/hospital/asyncDownloadFile", params).then(
        (re) => {
          if (re.status == "0") {
            let list = re.data;
            for (let item of list) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
            this.closeDocsDialog();
          } else {
            this.$message.error(re.message);
          }
        }
      );
    },
    // 跳转课程详情
    handleEdit(item) {
      this.$router.push({
        path: "/web/coursesIboughtInfo",
        query: {
          courseId: item.courseId,
          projectCourseId: item.projectCourseId,
        },
      });
    },
    /* 跳转学员列表 */
    handleAdd(row) {
      this.$router.push({
        path: "/web/studentList",
        query: {
          courseName: row.courseName,
          projectId: row.projectId,
          courseId: row.courseId,
          remainCount: row.remainCount,
          projectCourseId: row.projectCourseId,
        },
      });
    },
    // 初始化获取列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.orderTime && this.orderTime.length >= 1 && this.orderTime[0]) {
          params.startDate = this.orderTime[0];
      }
      if (this.orderTime && this.orderTime.length >= 2 && this.orderTime[1]) {
          params.endDate = this.orderTime[1];
      }

      this.doFetch({
        url: "/biz/projectCourse/hospital/purchasedCourses",
        params,
        pageNum,
      });
    },
    // 继续购买
    GenerateQRcode(row) {
      this.$router.push({
        path: "/web/courseCenterInfo",
        query: {
          courseId: row.courseId,
          projectCourseId: row.projectCourseId,
        },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          this.courseName = "";
          this.areaId = "";
          this.compType = "";
          this.compPhone = "";
          this.compName = "";
          this.pageNum = 1;
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.searchbox {
  padding: 0;
}
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
</style>
